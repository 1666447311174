.th-mobile-sidebar{
    background: #000;
}

/* .th-mobile-sidebar .ant-drawer .ant-drawer-body{
    padding: 0 !important;
} */

.th-mobile-sidebar .ant-drawer-wrapper-body .ant-drawer-body{
    padding: 0 !important;
}

.ant-layout .ant-layout-sider-trigger{
    background: linear-gradient(45deg, #48e28b, #4a8564) !important;

    /* background: #ff8105!important ; */
}

.menu-label .ant-menu-title-content{
    color: white;
    font-weight: 500;
    font-size: 15px;
}
.ant-menu-dark .ant-menu-item-selected{
    background: linear-gradient(50deg, #48e28b, #4a8564) !important;
}
@media(max-width:678px){
    .ant-layout .ant-layout-sider-trigger{
        background: linear-gradient(45deg, #48e28b, #4a8564) !important;
    }
}